<template>
  <div class="map-dialog">
    <div class="action">
      <el-button type="primary" size="small" @click="drawOnCarArea">绘制</el-button>
      <el-button size="small" @click="delOnCarArea">删除</el-button>
    </div>
    <div class="map_content" :style="mapStyle">
      <div class="toolBox">
        <div class="search marginR">
          <input id="inputx" placeholder="请输入地址搜索" />
        </div>
      </div>
      <div id="container2"></div>
    </div>
  </div>
</template>
<script>
// import searchMap from './map.vue'
import AMap from 'AMap'
export default {
  components:{
    // searchMap
  },
  props:{
    showTable: {
      type: Boolean,
      default: false
    },
    currentDialog:{
      type:Number,
      default:0
    },
    params:{
      type:Object,
      default:()=>{}
    }
  },
  data(){
    return{
      map: null,
      onCarArea: null,
      mouseTool: null,
      onCarMouseTool: null,
      onCarMarker: null,
      inputx: null,
      trailIcon: [
        '/static/static1/map/trailStart.png', // 起点
        '/static/static1/map/trailEnd.png', // 终点
        '/static/static1/map/trail.png' // 停车点
      ],
      latLngObj:{
        circleLat:0,
        circleLng:0,
        radius:0
      }
    }
  },
  computed: {
    mapStyle: function () {
      return `height:${this.showTable ? '50vh' : '100%'}`
    }
  },
  created () {
    
   },
  mounted () {
    this.loadmap() // 加载地图和相关组件;
  },
  destroyed(){
    this.map.clearMap()
  },
  methods:{
    //回显
    viewMask(row) {    
      this.map.clearMap()
      let latLng = new AMap.LngLat(row.circleLng,row.circleLat);
      var circle = new AMap.Circle({
        center: latLng,
        radius:row.radius, //半径
        borderWeight: 3,
        strokeColor: "#045642", 
        strokeOpacity: 1,
        strokeWeight: 6,
        strokeOpacity: 0.2,
        fillOpacity: 0.4,
        strokeStyle: 'solid',
        strokeDasharray: [10, 10], 
        fillColor: '#059370',
        zIndex: 50,
      })
      this.latLngObj={
        circleLat:row.circleLat,
        circleLng:row.circleLng,
        radius:row.radius
      }
      circle.setMap(this.map)
      // 缩放地图到合适的视野级别
      this.map.setFitView([ circle ])
    },
    /** 获取圆形数据 */
    savePath (data) {
      if (data) {
        this.latLngObj.circleLat = data.getCenter().lat
        this.latLngObj.circleLng = data.getCenter().lng
        this.latLngObj.radius = data.getRadius(); 
        this.$emit('getLatLng',this.latLngObj)
      } else {
        this.emptyObj();
      }
    },
    /** 加载地图和相关组件 */
    loadmap () {
      let map = new AMap.Map('container2', {
        mapStyle: 'amap://styles/fresh',
        zoom: 12,
        resizeEnable: true,
        expandZoomRange: true,
        center: [105.829757, 32.433668],//登录的时候获取的用户所在位置经纬度
        features: ['bg', 'road', 'building', 'point'],
        keyboardEnable: false
      })
      this.map = map
      let self = this
      self.map.plugin(
        [
          // "AMap.ToolBar",
          'AMap.PlaceSearch',
          'AMap.Autocomplete'
        ],
        function () {
          var autoOptions = {
            input: 'inputx'
          }
          // console.log(222,autoOptions)
          var autocomplete = new AMap.Autocomplete(autoOptions)
          var placeSearch = new AMap.PlaceSearch({
            map: self.map
          })
          AMap.event.addListener(autocomplete, 'select', function (e) {
            // console.log(111,e)
            // TODO 针对选中的poi实现自己的功能
            placeSearch.search(e.poi.name)
          })
        }
      )
    },
    // 区域绘制
    drawOnCarArea () {
      if(this.latLngObj.radius>0){
        return
      }
      let self = this
      if (self.onCarMouseTool) {
        self.onCarMouseTool.close()
      } else {
        self.map.plugin(['AMap.MouseTool'], function () {
          //鼠标工具
          self.onCarMouseTool = new AMap.MouseTool(self.map)
          // 监听draw件可获取画好的覆盖物
          self.onCarMouseTool.on('draw', function (e) {
            self.onCarArea = e.obj
            self.savePath(e.obj)
            let marker = new AMap.Marker({
              label:{
                content:`区域${self.currentDialog + 1}`
              },
              size: new AMap.Size(30, 30), // 图标尺寸
              position: e.obj.getCenter()
            })
            self.onCarMarker = marker
            marker.setMap(self.map)
          })
          /** 画圆 */
          self.onCarArea = self.onCarMouseTool.circle({
            strokeColor: '#045642', // 线颜色
            strokeWeight: 6,
            strokeOpacity: 0.2,
            fillColor: '#059370', // 填充颜色
            fillOpacity: 0.4,
            strokeStyle: 'solid'
          })
          self.onCarMouseTool.on('draw', function () {
            self.onCarMouseTool.close()
          })
        })
      }
    },
    emptyObj(){
      this.latLngObj={
          circleLat:0,
          circleLng:0,
          radius:0
        }
    },
    // 区域删除
    delOnCarArea () {
      this.map.clearMap()
      if (this.onCarArea) this.map.remove(this.onCarArea)
      if (this.onCarMarker) this.map.remove(this.onCarMarker)
      this.onCarMouseTool = null
      this.emptyObj();
      this.$emit('getLatLng',this.latLngObj)
    }
  }
}
</script>
<style lang="scss">
  .map-dialog{
    height: 50vh;
  }
  .amap-sug-result{
    z-index: 3000 !important;
  }
  .map_content {
  position: relative;
  #container2 {
    width: 100%;
    height: 100%;
  }
  .toolBox {
    position: absolute;
    z-index: 1;
    top: 5px;
    border-width: 0;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    .search {
      margin-left: 10px;
      border-radius: 4px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      div {
        text-indent: 10px;
      }
      input {
        width: 14vw;
        background: #fff;
        border-radius: 4px;
        padding-left: 5px;
        line-height: 32px;
        height: 32px;
        border: 1px solid #dcdfe6;
      }
      input:focus {
        outline: 1px solid #059370;
      }
    }
    & > span {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
      justify-content: center;
      align-items: center;
      background-color: #fff;
    }
  }
}
.action{
  margin-bottom: 12px;;
}
</style>