
<template>
  <div class="lost-found" ref="lostFound">
    <div class="function-buttons" ref="functionButtons" v-show="false">
      <el-button type="primary" size="small">新增</el-button>
    </div>
    <div class="left">
      <div class="search-form">
        <div>
          <el-button type="primary" size="small" @click="AddConditions" :disabled="searchForm.detailList.length>2">新增条件</el-button>
          <el-button type="primary" size="small" @click="onSubmit">查询</el-button>          
        </div>
        <el-form
          :model="searchForm"
          ref="searchForm"
          label-width="90px"
          label-position="left"
          :rules="rules"
        >
        <div v-for="(item,index) in searchForm.detailList" :key="index">
          <el-divider></el-divider>
          <p style="line-height:20px;">条件{{index+1}}</p>
          <el-form-item label="开始时间：" :prop="`detailList[${index}].startTime`" :rules="rules.startTime">
            <el-date-picker
              type="datetime"
              placeholder="选择开始时间"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="item.startTime"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间：" :prop="`detailList[${index}].endTime`" :rules="rules.endTime">
            <el-date-picker
              type="datetime"
              placeholder="选择结束时间"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="item.endTime"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="途径区域：">
            <el-button type="primary" size="small" @click="openDailog(index)">绘制</el-button>
            <el-button type="info" size="small" @click="delConditions(index)" v-if="searchForm.detailList.length>1">删除条件</el-button>
          </el-form-item>
        </div>
        </el-form>
      </div>
    </div>
    <div class="right">
      <searchMap ref="searchMap" :showTable="showTable" :offCarArea="offCarArea" :onCarArea="onCarArea" :carArea="CarArea" />
      <div class="lostArticle" v-if="showTable">
        <el-table :data="tableData" stripe height="31.5vh">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="cph" label="车牌号"></el-table-column>
          <el-table-column prop="companyName" label="所属车队"></el-table-column>
          <el-table-column prop="conditionNoList" label="满足条件"></el-table-column>
          <el-table-column prop="driverName" label="当前驾驶员"></el-table-column>
          <el-table-column prop="phone" label="联系电话"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="onTrailPlay(scope.row)" size="small" type="primary">轨迹回放</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination" ref="pagination">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-size="searchForm.pageSize"
            :current-page="searchForm.currentPage"
            @size-change="onSizeChange"
            @current-change="onCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
    <!-- 绘制图弹窗 -->
    <el-dialog
      title="绘制区域"
      width="50%"
      top="10vh"
      :visible.sync="mapVisible"
      v-if="mapVisible"
    >
      <div style="padding-bottom:40px;">
        <mapVisible class="body" ref="mapVisible" :currentDialog="currentDialog" @getLatLng="val=>maplnglat=val"></mapVisible>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="mapVisible = false"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="makeSureMapCircle"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import searchMap from './components/map.vue'
import mapVisible from './components/mapVisible.vue'
import { formatDate } from '@/common/utils/index'
import { lostFindList } from '@/api/lib/taxi-api.js'
import moment from "moment";

export default {
  name: 'lostFound',
  components: { searchMap,mapVisible },
  data () {
    return {
      mapVisible:false,
      showTable: false,
      tableData: [],
      searchForm: {
        currentPage: 1,
        pageSize: 10,
        detailList:[
          {
            startTime: '',
            endTime: '',
            circleLat: 0, // 圆心纬度
            circleLng: 0, // 圆心经度
            radius: 0, // 半径（米）
            conditionNo:1
          }
        ]
      },
      total: 0,
      curParams:{},
      currentDialog:0,
      pickerOptions: {
        disabledDate (time) {
          return new Date(time) > new Date()
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      rules: {
        startTime:[
          {  required: true, message: '请选择开始时间', trigger: 'blur' }
        ],
        endTime:[
          {  required: true, message: '请选择结束时间', trigger: 'blur' }
        ],
      },
      maplnglat:{},//绘制的区域信息
      trailArr:[],
      pointArr:[],
      offCarArea: null,
      onCarArea: null,
      CarArea: null,
    }
  },
  methods: {
    makeSureMapCircle(){
      this.mapVisible = false;
      let {circleLat,circleLng,radius} = this.maplnglat;
      this.searchForm.detailList[this.currentDialog].circleLat = circleLat;
      this.searchForm.detailList[this.currentDialog].circleLng = circleLng;
      this.searchForm.detailList[this.currentDialog].radius = radius;
      // this.sendPath(this.currentDialog,this.maplnglat)
    },
    AddConditions(){
      if(this.searchForm.detailList.length<3){
        this.searchForm.detailList.push({
          startTime: '',
          endTime: '',
          circleLat: 0, // 圆心纬度
          circleLng: 0, // 圆心经度
          radius: 0, // 半径（米）
          // conditionNo:this.searchForm.detailList.length+1
        })
      }
    },//新增条件
    delConditions(index){
      this.searchForm.detailList.splice(index,1);
      if(index===0){
        this.onCarArea = null;
      }else if(index===1){
        this.offCarArea=null;
      }else if(index===2){
        this.CarArea=null;
      }
    },//删除条件
    openDailog(index){
      this.currentDialog = index;
      let curParams = this.searchForm.detailList[index]
      this.mapVisible = true;
      //回显
      if(curParams.radius){
        this.$nextTick(()=>{  
          this.$refs.mapVisible.viewMask(curParams)
        })
      }
    },
    onSubmit () {
      let dataList = this.searchForm.detailList;
      let canSubmit = true;
      this.$refs.searchForm.validate((valid) => {
        if(valid){
          for(let i=0;i<dataList.length;i++){            
            if (moment(new Date(dataList[i].startTime)).format("YYYY-MM-DD HH:mm") > moment(new Date(dataList[i].endTime)).format("YYYY-MM-DD HH:mm")) {
              this.$message.error('开始时间不能大于结束时间')
              return 
            }//判断始终为false
            if (new Date(dataList[i].endTime) - new Date(dataList[i].startTime) > 1/3 * 60 * 60 * 1000) {
              this.$message.error('开始时间和结束时间的间隔不超过20min')
              return 
            }
            if(dataList[i].circleLat === 0){
               canSubmit = false
               this.$message.error('请绘制区域')
               return
            }
          }
          if(canSubmit){
            // console.log('提交数据',dataList)
            dataList.map((item,index)=>{
              item.conditionNo=index+1;
              //展示绘制的图
              this.sendPath(index,item)
            })
            this.getTableData(dataList)
            
          }
        }
      })
    },
    sendPath(index,data){
      if(index===0){
        this.onCarArea = data;
      }else if(index===1){
        this.offCarArea=data;
      }else if(index===2){
        this.CarArea=data;
      }
      this.$refs.searchMap.viewMask();
    },
    /** 获取圆形数据 */
    sendPathOld (type, data) {
      switch (type) {
        case 'oncar':
          if (data) {
            let circleLat = data.getCenter().lat
            let circleLng = data.getCenter().lng
            let radius = data.getRadius()
            this.searchForm.inDetail.circleLat = circleLat// 圆心纬度
            this.searchForm.inDetail.circleLng = circleLng// 圆心经度
            this.searchForm.inDetail.radius = radius// 半径（米）
          } else {
            //传入index，清楚index对应的圆的绘制
            this.clearCircleData('inDetail')
          }
          break
        case 'offcar':
          if (data) {
            let circleLat = data.getCenter().lat
            let circleLng = data.getCenter().lng
            let radius = data.getRadius()
            this.searchForm.outDetail.circleLat = circleLat// 圆心纬度
            this.searchForm.outDetail.circleLng = circleLng// 圆心经度
            this.searchForm.outDetail.radius = radius// 半径（米）
          } else {
            this.clearCircleData('outDetail')
          }
          break

        default:
          break
      }
    },
    /** 清除圆形数据 */
    clearCircleData (type) {
      this.searchForm[type].circleLat = 0// 圆心纬度
      this.searchForm[type].circleLng = 0// 圆心经度
      this.searchForm[type].circleLat = 0// 半径（米）
    },
    // 获取表格数据
    getTableData () {
      lostFindList(this.searchForm).then(res => {
        if (res.code === 1000) {
          this.tableData = res.data.list
          this.total = res.data.total
          this.showTable = true
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.searchForm.currentPage = page
      this.getTableData()
    },

    // 当前条数改变
    onSizeChange (size) {
      this.searchForm.pageSize = size
      this.searchForm.currentPage = 1
      this.getTableData()
    },
    // 轨迹回放
    onTrailPlay (row) {
      this.$refs.searchMap.onTrailPlay(row)
      //拿到轨迹点就绘制线路
      //循环数组拿到最小时间作为开始时间，最大时间作为结束时间
      // let minTime = '';
      // let maxTime = '';
      // if(this.searchForm.detailList.length===1){
      //   minTime = this.searchForm.detailList[0].startTime;
      //   maxTime = this.searchForm.detailList[0].endTime;
      // }
      // this.searchForm.detailList.map(item=>{
      //   moment(new Date(dataList[i].startTime)).format("YYYY-MM-DD HH:mm")
      //   item.startTime
      // })
      // console.log('时间',minTime,maxTime)
    },
    
    
  },
  created () {
  },
  mounted () { },
  destroyed () {

  }
}
</script>

<style lang="scss" scoped>
.lost-found {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  @include themify() {
    .left {
      width: 320px;
      margin-right: 20px;
      overflow-y: auto;
      .search-form {
        padding-bottom: 10px;
        .el-form {
          .el-form-item {
            margin-bottom: 14px;
            &:last-child {
              margin-bottom: 0;
            }
            >>> .el-form-item__content {
              margin-top: 5px;
            }
          }
        }
        .el-divider--horizontal {
          margin: 2vh 0;
        }
      }
      .right-text {
        display: flex;
        justify-content: flex-end;
        padding: 10px 2px;
      }
    }
    .right {
      width: calc(100% - 320px);
      background-color: #ffffff;
    }
  }
}

.hidden {
  display: none;
}

.theme-project-taxi {
  .lost-found {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
