
<template>
  <div class="map_content" :style="mapStyle">
    <!-- <div class="toolBox">
      <div class="search marginR">
        <input id="tipinput" placeholder="请输入地址搜索" />
      </div>
    </div> -->
    <div id="container"></div>
  </div>
</template>

<script>
import AMap from 'AMap'
export default {
  name: 'searchMap',
  components: {},
  props: {
    showTable: {
      type: Boolean,
      default: false
    },
    offCarArea: {
      type: Object,
      default: null
    },
    onCarArea: {
      type: Object,
      default: null
    },
    carArea: {
      type: Object,
      default: null
    },
  },
  watch:{
    onCarArea(val){      
      if(val){
        this.viewMask()
      }else{
        this.delOnCarArea()
      }
    },
    offCarArea(val){
      if(val){
        this.viewMask()
      }else{
        this.delOnCarArea()
      }
    },
    carArea(val){
     if(val){
        this.viewMask()
      }else{
        this.delOnCarArea()
      }
    }
  },
  data () {
    return {
      trailIcon: [
        require('@/assets/images/map/trailStart.png'), //起点
        require('@/assets/images/map/trailEnd.png'), //终点
        require('@/assets/images/map/trail.png') //停车点
      ],
      map: null,
      mouseTool: null,
      onCarMouseTool: null,
      offCarMarker: null,
      onCarMarker: null,
      tipinput: null,
    }
  },
  methods: {
    /** 加载地图和相关组件 */
    loadmap () {
      let map = new AMap.Map('container', {
        mapStyle: 'amap://styles/fresh',
        zoom: 12,
        resizeEnable: true,
        expandZoomRange: true,
        center: [105.829757, 32.433668],//登录的时候获取的用户所在位置经纬度
        features: ['bg', 'road', 'building', 'point']
      })
      this.map = map
      let self = this
      // self.map.plugin(
      //   [
      //     // "AMap.ToolBar",
      //     'AMap.PlaceSearch',
      //     'AMap.Autocomplete'
      //   ],
      //   function () {
      //     var autoOptions = {
      //       input: 'tipinput'
      //     }

      //     var autocomplete = new AMap.Autocomplete(autoOptions)
      //     var placeSearch = new AMap.PlaceSearch({
      //       map: self.map
      //     })
      //     AMap.event.addListener(autocomplete, 'select', function (e) {
      //       // TODO 针对选中的poi实现自己的功能
      //       placeSearch.search(e.poi.name)
      //     })
      //   }
      // )
    },
    //区域绘制回显
    viewMask() { 
      this.map.clearMap()
      if(this.onCarArea) this.mapSet(this.onCarArea,'#045642')
      if(this.offCarArea)this.mapSet(this.offCarArea,'#FF33FF')
      if(this.carArea) this.mapSet(this.carArea,'red')
    },
    mapSet(data,color){
      let {circleLat,circleLng,radius} = data;
      let latLng = new AMap.LngLat(circleLng,circleLat);
      var circle = new AMap.Circle({
        center: latLng,
        radius:radius, //半径
        borderWeight: 3,
        strokeColor:color, //#FF33FF,red
        strokeOpacity: 1,
        strokeWeight: 6,
        strokeOpacity: 0.2,
        fillOpacity: 0.4,
        strokeStyle: 'solid',
        strokeDasharray: [10, 10], 
        fillColor: '#059370',
        zIndex: 50,
      })
      circle.setMap(this.map)
      // 缩放地图到合适的视野级别
      this.map.setFitView([ circle ])
    },
    // 下车区域绘制--old
    drawOffCarArea () {
      let self = this
      if (self.mouseTool) {
        self.mouseTool.close()
      } else {
        self.map.plugin(['AMap.MouseTool'], function () {
          self.mouseTool = new AMap.MouseTool(self.map)
          // 监听draw件可获取画好的覆盖物
          self.mouseTool.on('draw', function (e) {
            self.offCarArea = e.obj
            self.$emit('sendPath', 'offcar', e.obj)
            let marker = new AMap.Marker({
              icon: new AMap.Icon({
                image:
                  self.trailIcon[1],
                imageSize: new AMap.Size(24, 30),
                size: new AMap.Size(30, 30) // 图标尺寸
              }),
              size: new AMap.Size(30, 30), // 图标尺寸
              position: e.obj.getCenter()
            })
            self.offCarMarker = marker
            marker.setMap(self.map)
          })
          /** 画圆 */
          self.offCarArea = self.mouseTool.circle({
            strokeColor: '#045642', // 线颜色
            strokeWeight: 6,
            strokeOpacity: 0.2,
            fillColor: '#059370', // 填充颜色
            fillOpacity: 0.4,
            strokeStyle: 'solid'
          })
          self.mouseTool.on('draw', function () {
            self.mouseTool.close()
          })
        })
      }
    },
    // 上车区域绘制--old
    drawOnCarArea () {
      let self = this
      if (self.onCarMouseTool) {
        self.onCarMouseTool.close()
      } else {
        self.map.plugin(['AMap.MouseTool'], function () {
          self.onCarMouseTool = new AMap.MouseTool(self.map)
          // 监听draw件可获取画好的覆盖物
          self.onCarMouseTool.on('draw', function (e) {
            self.onCarArea = e.obj
            self.$emit('sendPath', 'oncar', e.obj)
            let marker = new AMap.Marker({
              icon: new AMap.Icon({
                image:
                  self.trailIcon[0],
                imageSize: new AMap.Size(24, 30),
                size: new AMap.Size(30, 30) // 图标尺寸
              }),
              size: new AMap.Size(30, 30), // 图标尺寸
              position: e.obj.getCenter()
            })
            self.onCarMarker = marker
            marker.setMap(self.map)
          })
          /** 画圆 */
          self.onCarArea = self.onCarMouseTool.circle({
            strokeColor: '#045642', // 线颜色
            strokeWeight: 6,
            strokeOpacity: 0.2,
            fillColor: '#059370', // 填充颜色
            fillOpacity: 0.4,
            strokeStyle: 'solid'
          })
          self.onCarMouseTool.on('draw', function () {
            self.onCarMouseTool.close()
          })
        })
      }
    },
    // 下车区域删除--old
    delOffCarArea () {
      if (this.offCarArea) this.map.remove(this.offCarArea)
      if (this.offCarMarker) this.map.remove(this.offCarMarker)
      this.mouseTool = null
      this.$emit('sendPath', 'offcar')
    },
    // 区域删除
    delOnCarArea () {
      this.map.clearMap();
      this.viewMask()
    },
    initPathSimplifier () {
      var self = this
      AMapUI.load(
        ['ui/misc/PathSimplifier', 'lib/$'],
        function (PathSimplifier, $) {
          if (!PathSimplifier.supportCanvas) {
            alert('当前环境不支持 Canvas！')
            return
          }
          if (window.pathSimplifierIns) {
            //通过该方法清空上次传入的轨迹
            window.pathSimplifierIns.setData([])
          }
          var pathSimplifierIns = new PathSimplifier({
            zIndex: 100,
            //autoSetFitView:false,
            map: self.map, //所属的地图实例
            getPath: function (pathData, pathIndex) {
              return pathData.path
            },
            
            renderOptions: {
              renderAllPointsIfNumberBelow: -1,
              pathTolerance: 0,
              keyPointTolerance: -1,
              pathLineStyle: {
                lineWidth: 3,
                strokeStyle: '#0B4FD5',
                dirArrowStyle: false
              },
              pathLineHoverStyle: {
                lineWidth: 3,
                strokeStyle: '#0B4FD5',
                dirArrowStyle: false
              },
              pathLineSelectedStyle: {
                lineWidth: 3,
                strokeStyle: '#0B4FD5',
                dirArrowStyle: false
              },
              dirArrowStyle: {
                stepSpace: 80,
                strokeStyle: '#ffffff',
                lineWidth: 2
              }
            }
          })
          window.pathSimplifierIns = pathSimplifierIns
          //设置数据
          pathSimplifierIns.setData([
            {
              name: '路线0',
              path: self.pointArr
            }
          ])
          pathSimplifierIns.setSelectedPathIndex(0)
          pathSimplifierIns.on('pointClick', function (e, info) {
            // console.log('Click: ' + self.trailArr[info.pointIndex].gt)
          })
          //对第一条线路（即索引 0）创建一个巡航器
          // let image = PathSimplifier.Render.Canvas.getImageContent(require('@/assets/images/map/car6.png'), onload, onerror)
          var pathNavigatorStyles = [
            {
              width: 18,
              height: 18,
              strokeStyle: '#0B4FD5',
              fillStyle: '#0B4FD5',
              pathLinePassedStyle: {
                lineWidth: 3,
                strokeStyle: '#AF5',
                dirArrowStyle: false
              }
            },
            {
              width: 18,
              height: 18,
              strokeStyle: 'red',
              fillStyle: 'red',
              pathLinePassedStyle: {
                lineWidth: 3,
                strokeStyle: '#AF5',
                dirArrowStyle: false
              }
            }
          ]
          function extend (dst) {
            if (!dst) {
              dst = {}
            }
            var slist = Array.prototype.slice.call(arguments, 1)
            for (var i = 0, len = slist.length; i < len; i++) {
              var source = slist[i]
              if (!source) {
                continue
              }
              for (var prop in source) {
                if (source.hasOwnProperty(prop)) {
                  dst[prop] = source[prop]
                }
              }
            }
            return dst
          }
          self.navgtr = null
          self.navgtr = pathSimplifierIns.createPathNavigator(0, {
            loop: false, //循环播放
            speed: 10100, //巡航速度，单位千米/小时
            pathNavigatorStyle: extend({}, pathNavigatorStyles[0])
          })
          self.navgtr.on('move', function (data, position) {
            let idx = position.dataItem.pointIndex //走到了第几个点
            self.replayform = self.trailArr[idx]
            //获取到pathNavigatorStyle的引用
            var pathNavigatorStyle = self.navgtr.getStyleOptions()
            //覆盖修改
            extend(
              pathNavigatorStyle,
              pathNavigatorStyles[self.trailArr[idx].ac === null ? 0 : 1]
            )
          })
        }
      )
    },
    onTrailPlay (row) {
      this.map.clearMap()
      let self = this;
      if(row.historyPosition&&row.historyPosition.length !==0){
        var arr = []
          var objArr = []          
          var positions = [row.historyPosition[0]] // 轨迹de开始，停车，结束点
          for (let i = 0; i < row.historyPosition.length; i++) {
            const element = row.historyPosition[i]
            arr.push([element.longitude, element.latitude])
            objArr.push({
              longitude: element.longitude,
              latitude: element.latitude
            })
            if (element.pt && (i !== 0 || i !== row.historyPosition.length - 1)) {
              positions.push(element)
            }
          }
          if (row.historyPosition.length > 1) {
            positions.push(row.historyPosition[row.historyPosition.length - 1])
          }
          self.trailArr = [...row.historyPosition] // 播放轨迹表单显示
          self.pointArr = arr // 轨迹线路
          self.pathDetails = objArr // 点击保存线路
          self.initPathSimplifier()
          self.drawLine(self.pointArr, positions) // 展示线路  第二个参数:地图是否setFitView
      }else{
        self.trailArr = [] // 播放轨迹表单显示清空
        self.pointArr = [] // 轨迹线路清空
        self.$message({
          message: '暂无轨迹数据',
          type: 'warning'
        })
      }
    },
    /* 轨迹回放 绘制线路 */
    drawLine (arr, positions) {
      let self = this
      // 清除地图
      let polylines = []
      // 只有第一次进来的时候将arr赋值给this.lineArr 同时获取剪裁后的点
      self.lineArr = arr
      for (var i = 1; i < arr.length - 1; i++) {
        //ac有值表示报警定位点--绘制红线
        if (self.trailArr[i].ac) {
          polylines.push(self.returnLine([arr[i - 1], arr[i]], 'red'))
        }
      }
      /** 起始点marker */
      positions.forEach((item, i) => {
        // console.log(item,i)
        let marker = new AMap.Marker({
          icon: new AMap.Icon({
            image:
              i === 0
                ? self.trailIcon[i]
                : i === positions.length - 1
                  ? self.trailIcon[1]
                  : self.trailIcon[2],
            imageSize: new AMap.Size(24, 30),
            size: new AMap.Size(30, 30) // 图标尺寸
          }),
          size: new AMap.Size(30, 30), // 图标尺寸
          zIndex: i === 0 || i === positions.length - 1 ? 200 : 100,
          position: [item.longitude, item.latitude]
        })
        marker.setMap(self.map)
      })
      // 缩放地图到合适的视野级别
      self.map.setFitView(polylines)
    },
    /** 创建polyline对象 */
    returnLine (spliceArr, color) {
      var polyline = new AMap.Polyline({
        map: this.map,
        path: spliceArr,
        borderWeight: 1,
        strokeColor: color,
        strokeOpacity: 1,
        strokeWeight: 3,
        strokeStyle: 'solid',
        strokeDasharray: [10, 5],
        lineJoin: 'round',
        lineCap: 'round',
        geodesic: true, // 绘制大地线
        zIndex: 50
      })
      return polyline
    },

  },
  computed: {
    mapStyle: function () {
      return `height:${this.showTable ? '50vh' : '100%'}`
    }
  },
  created () { },
  mounted () {
    this.loadmap() // 加载地图和相关组件;
  }
}
</script>

<style lang="scss" scoped>
.map_content {
  position: relative;
  #container {
    width: 100%;
    height: 100%;
  }
  .toolBox {
    position: absolute;
    z-index: 1;
    top: 5px;
    border-width: 0;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    .search {
      margin-left: 10px;
      border-radius: 4px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      div {
        text-indent: 10px;
      }
      input {
        width: 14vw;
        background: #fff;
        border-radius: 4px;
        padding-left: 5px;
        line-height: 32px;
        height: 32px;
        border: 1px solid #dcdfe6;
      }
      input:focus {
        outline: 1px solid #059370;
      }
    }
    & > span {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
      justify-content: center;
      align-items: center;
      background-color: #fff;
    }
  }
}
</style>
